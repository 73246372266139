import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, AfterViewInit, EventEmitter, Output, HostListener, ViewChild, ElementRef } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
declare var $: any;
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {

  @Output() toggleSidebar = new EventEmitter<void>();
  isMenuOpen = false;
  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  /* http: any;
  router: any; */

  @ViewChild('menuRef') menuElement: ElementRef;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.menuElement.nativeElement.contains(event.target) && this.isMenuOpen) {
      // El clic fue fuera del menú, cierra el menú
      this.toggleSidebar.emit();
      this.isMenuOpen = false;
    }
  }
  
  callToMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.toggleSidebar.emit()
  }
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private http: HttpClient,
    ) {}

  // This is for Notifications
  /* notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ]; */

  // This is for Mymessages
  /* mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ]; */

  ngAfterViewInit() {}

  logout(){
    /* console.log("logout!!!"); */

    localStorage.clear();
    /* localStorage.clear(); */
    this.router.navigate(['/authentication/login']);
    /* const httpOptions = {
      headers: new HttpHeaders({
         'Content-Type': 'application/x-www-form-urlencoded',
      })
     }; */


    /* const body = new HttpParams()
      .append('user', this.f.username.value)
      .append('password', this.f.password.value) */

    /* this.http.post(environment.apiUrlAuth + 'logout', body ).subscribe({
      next: data => {
          //console.log(data);
      },
      error: error => {
        console.error('There was an error!', error);
      }
      }); */

  }
}
