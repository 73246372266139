import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentTokenSubject: BehaviorSubject<string>;
  public readonly currentToken: Observable<string>;

  private currentUserSubject: BehaviorSubject<any>;
  public readonly currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentTokenSubject = new BehaviorSubject<string>(localStorage.getItem('accessToken'));
    this.currentToken = this.currentTokenSubject.asObservable();
    this.currentUserSubject = new BehaviorSubject<string>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): string {
    return this.currentUserSubject.value;
  }

  setCurrentUser(user: any): void {
    this.currentUserSubject.next(user);
  }

  public get currentTokenValue(): string {
    return this.currentTokenSubject.value;
  }

  setCurrentToken(token: string): void {
    this.currentTokenSubject.next(token);
  }

  login(form: {username: string, password: string, rememberMe: boolean}) {
    let body = {email: form.username, password: form.password};
    return this.http.post<any>(environment.apiUrlAuth + '/login', body)
        .pipe(map(response => {
          let accessToken = response.token.split('|')[1];
          let expirationTime = new Date();
          expirationTime.setHours(expirationTime.getHours() + 2);

          this.setCurrentUser(response.user);
          this.setCurrentToken(accessToken);

          if(form.rememberMe){
            localStorage.setItem('accessToken', JSON.stringify({accessToken: accessToken, expirationTime: expirationTime}));
            localStorage.setItem('user', JSON.stringify(response.user));
          } else {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');
          }

          return accessToken;
        }));
  }
}
