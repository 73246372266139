import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private routes: Router, private authService: AuthService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    //SI HAY DATOS GUARDADOS
    if(localStorage.getItem('accessToken') != null) {
      let expirationTime = JSON.parse(localStorage.getItem('accessToken')).expirationTime;
      let now = JSON.parse(JSON.stringify(new Date()));
      if(expirationTime < now){
        //SI HAN CADUCADO LOS BORRAMOS Y REDIRIGIMOS AL LOGIN
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        this.routes.navigate(['/authentication/login']);
        return false;
      } else return true; //SI NO HAN CADUCADO PUEDE PASAR 
    } else {
      //SI NO HAY DATOS GUARDADOS, LOS INTENTAMOS RECIBIR DEL SERVICIO
      if(this.authService.currentTokenValue != null) return true;
      else {
        this.routes.navigate(['/authentication/login']);
        return false;
      }
    }
  }
}